import React from "react";
import { Redirect, NavLink } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AuthContext from "../../AuthContext";
import logo from "../../assets/logo_no_text.png";

function Copyright() {
  return (
    <Typography color="textSecondary" align="center">
      {"Copyright © "}
      MGComm {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  navLink: {
    color: "#3f51b5",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  contactUsText: {
    textAlign: "center",
    marginTop: "24px",
    padding: "24px 8px",
    border: "1px solid rgba(0,0,0,0.14)",
    borderRadius: "8px",
  },
  logo: {
    marginBottom: "24px",
  },
}));

const SignUpPage = ({ history }) => {
  const classes = useStyles();
  // const notifications = useContext(NotificationsContext);
  // const [state, setState] = useState({
  //   username: "",
  //   password: "",
  //   domain: "",
  //   firstName: "",
  //   email: "",
  //   lastName: "",
  // });
  // const [errors, setErrors] = useState({
  //   username: false,
  //   password: false,
  //   domain: false,
  //   firstName: false,
  //   email: false,
  //   lastName: false,
  // });

  // const handleChange = (event) => {
  //   setErrors({
  //     ...errors,
  //     [event.target.name]: event.target.required && !event.target.value,
  //   });
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  // const register = (event, auth) => {
  //   event.preventDefault();
  //   let formIsValid = true;
  //   const errorsCopy = { ...errors };
  //   for (const key in state) {
  //     if (!state[key]) {
  //       errorsCopy[key] = true;
  //       formIsValid = false;
  //     }
  //   }
  //   setErrors({
  //     ...errorsCopy
  //   });
  //   if (!formIsValid) {
  //     notifications.showError("Please fill in all mandatory fields!");
  //     return;
  //   }
  //   auth
  //     .attemptRegister(state)
  //     .then(response => {
  //       history.push("/");
  //       notifications.showSuccess("Registration successful!");
  //     })
  //     .catch(error => {
  //       notifications.showError("Registration process failed!");
  //     });
  // };

  return (
    <AuthContext.Consumer>
      {(auth) => {
        return auth.isLoggedIn() ? (
          <Redirect to="/" />
        ) : (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <img src={logo} alt="MGComm" className={classes.logo} />
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>
              <Typography component="p" className={classes.contactUsText}>
                Please{" "}
                <Link
                  href="mailto:sales@mg-comm.com?subject=Registration request for MGComm SMS Portal"
                  className={classes.navLink}
                >
                  contact us
                </Link>{" "}
                to finalize your registration process or{" "}
                <NavLink to="/login" className={classes.navLink}>
                  login
                </NavLink>{" "}
                if you already have an account.
              </Typography>
              {/* <form
                className={classes.form}
                onSubmit={event => register(event, auth)}
                noValidate
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="username"
                      label="Username"
                      name="username"
                      autoComplete="username"
                      value={state.username}
                      error={errors.username}
                      onChange={handleChange}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="domain"
                      label="Customer"
                      name="domain"
                      autoComplete="domain"
                      value={state.domain}
                      error={errors.domain}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={state.password}
                      error={errors.password}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      value={state.firstName}
                      error={errors.firstName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lname"
                      value={state.lastName}
                      error={errors.lastName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="email"
                      label="Email"
                      type="email"
                      id="email"
                      value={state.email}
                      error={errors.email}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign Up
                </Button>
                <Grid container justify="flex-end">
                  <Grid item>
                    <NavLink to="/login" className={classes.navLink}>
                      Already have an account? Sign in
                    </NavLink>
                  </Grid>
                </Grid>
              </form> */}
            </div>
            <Box mt={5}>
              <Copyright />
            </Box>
          </Container>
        );
      }}
    </AuthContext.Consumer>
  );
};

export default SignUpPage;
