import React, { useState, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PrivateRoute from "./auth/PrivateRoute";
import auth from "./auth/Auth";
import AuthContext from "./AuthContext";

import LoginPage from "./components/auth/LoginPage";
import SignupPage from "./components/auth/SignupPage";
import DashboardPage from "./components/dashboard/DashboardPage";
import PaymentsPage from "./components/payments/PaymentsPage";
import CreatedPaymentsPage from "./components/payments/CreatedPaymentsPage";
import CancelledPaymentsPage from "./components/payments/CancelledPaymentsPage";
import SMSPage from "./components/sms/SMSPage";
import ContactsPage from "./components/contacts/ContactsPage";
import EditContactsPage from "./components/contacts/EditContactsPage";
import CreateContactsPage from "./components/contacts/CreateContactsPage";
import PageNotFound from "./components/common/PageNotFound";
import Header from "./components/common/Header";

import "./App.css";
import { CssBaseline, Snackbar, SnackbarContent } from "@material-ui/core";
import AccountPage from "./components/account/AccountPage";
import NotificationsContext from "./NotificationsContext";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: red[600]
  }
}));

function App({ history, actions }) {
  const classes = useStyles();
  const [menuOpened, setMenuOpened] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(auth.isLoggedIn());
  const [successNotif, setSuccessNotif] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorNotif, setErrorNotif] = useState("");
  const [openError, setOpenError] = useState(false);

  const loggedStateHandler = () => {
    setLoggedIn(auth.isLoggedIn());
  };

  useEffect(() => {
    auth.addLoggedStateChangeHandler(loggedStateHandler);
    return () => {
      auth.removeLoggedStateChangeHandler(loggedStateHandler);
    };
  }, []);

  const menuToggled = _menuOpened => {
    setMenuOpened(_menuOpened);
  };
  const handleLogout = () => {
    auth.logout();
    actions.logout();
    history.push("/login");
  };
  const notifications = {
    showSuccess: message => {
      setSuccessNotif(message);
      setOpenSuccess(true);
    },
    showError: message => {
      setErrorNotif(message);
      setOpenError(true);
    }
  };
  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };
  const handleErrorClose = () => {
    setOpenError(false);
  };
  return (
    <>
      <CssBaseline />
      <div
        className={clsx("main-content", {
          "menu-opened": isLoggedIn && menuOpened
        })}
      >
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleSuccessClose}
        >
          <SnackbarContent
            className={classes.success}
            message={
              <span
                id="client-snackbar"
                style={{ display: "flex", alignItems: "center" }}
              >
                {successNotif}
              </span>
            }
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={openError}
          autoHideDuration={6000}
          onClose={handleErrorClose}
        >
          <SnackbarContent
            className={classes.error}
            message={
              <span
                id="client-snackbar"
                style={{ display: "flex", alignItems: "center" }}
              >
                {errorNotif}
              </span>
            }
          />
        </Snackbar>
        <NotificationsContext.Provider value={notifications}>
          <AuthContext.Provider value={auth}>
            {isLoggedIn ? (
              <Header
                menuOpened={menuOpened}
                onLogout={handleLogout}
                onMenuToggled={menuToggled}
              ></Header>
            ) : (
              ""
            )}
            <Switch>
              <Route path="/login" component={LoginPage} />
              <Route path="/signup" component={SignupPage} />
              <PrivateRoute exact path="/" component={DashboardPage} />
              <PrivateRoute
                path="/payments/cancelled"
                component={CancelledPaymentsPage}
              />
              <PrivateRoute
                path="/payments/created"
                component={CreatedPaymentsPage}
              />
              <PrivateRoute path="/payments" component={PaymentsPage} />
              <PrivateRoute path="/sms" component={SMSPage} />
              <PrivateRoute
                path="/contacts/create"
                component={CreateContactsPage}
              />
              <PrivateRoute
                path="/contacts/edit/:id"
                component={EditContactsPage}
              />
              <PrivateRoute path="/contacts" component={ContactsPage} />
              <PrivateRoute path="/account" component={AccountPage} />
              <Route component={PageNotFound} />
            </Switch>
          </AuthContext.Provider>
        </NotificationsContext.Provider>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      logout: bindActionCreators(() => {
        return { type: "USER_LOGOUT" };
      }, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
