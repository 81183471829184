import initialState from "./initialState";
import * as types from "../actions/actionTypes";

//TODO: Move PayPal URL Out of here!!!
export default function accountDataReducer(
  state = initialState.accountData,
  action
) {
  switch (action.type) {
    case types.UPDATE_ACCOUNT_DATA_SUCCESS:
      return { ...action.accountData, payPalUrl: "" };
    case types.CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payPalUrl: action.payment && action.payment.payPalURL
      };
    case types.GET_ACCOUNT_DATA_SUCCESS:
      return { ...action.accountData, payPalUrl: "" };
    default:
      return state;
  }
}
