import React, { useState, useEffect, useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Money from "@material-ui/icons/Money";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import { Grid } from "@material-ui/core";
import * as billingDataActions from "../../redux/actions/billingDataActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NotificationsContext from "../../NotificationsContext";
import Loading from "../common/Loading";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  navLink: {
    color: "#3f51b5",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  success: {
    backgroundColor: green[600]
  }
}));

const BillingDataPage = ({ billingData, actions }) => {
  const classes = useStyles();
  const notifications = useContext(NotificationsContext);

  if (billingData === null)
    actions.loadBillingData().catch(error => {
      notifications.showError(
        "Error while loading your data - please try again!"
      );
    });

  const [_billingData, setBillingData] = useState(billingData);
  const [errors, setErrors] = useState({
    email: false,
    companyName: false,
    vat: false,
    country: false,
    city: false,
    zipCode: false,
    address: false,
    phone: false
  });

  useEffect(() => {
    setBillingData(billingData);
  }, [billingData]);

  const updateBillingData = event => {
    event.preventDefault();

    let formIsValid = true;
    const errorsCopy = { ...errors };
    for (const key in _billingData) {
      if (!_billingData[key]) {
        errorsCopy[key] = true;
        formIsValid = false;
      }
    }
    setErrors({
      ...errorsCopy
    });
    if (!formIsValid) {
      notifications.showError("Please fill in all mandatory fields!");
      return;
    }
    actions
      .updateBillingData(_billingData)
      .then(() => {
        notifications.showSuccess("Billing Data updated successfully!");
      })
      .catch(error => {
        notifications.showError("Billing Data update failed!");
      });
  };

  const handleChange = event => {
    setBillingData({
      ..._billingData,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: event.target.required && !event.target.value
    });
  };

  return !_billingData ? (
    <Loading />
  ) : (
    <Container component="main" maxWidth="lg">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Money />
        </Avatar>
        <Typography component="h1" variant="h5">
          Billing Data
        </Typography>
        <form
          className={classes.form}
          onSubmit={event => updateBillingData(event)}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                id="companyName"
                label="Company Name"
                name="companyName"
                value={_billingData.companyName}
                error={errors.companyName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                id="vat"
                label="VAT Number"
                name="vat"
                value={_billingData.vat}
                error={errors.vat}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                id="country"
                label="Country"
                name="country"
                value={_billingData.country}
                error={errors.country}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                id="city"
                label="City"
                name="city"
                value={_billingData.city}
                error={errors.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                id="zipCode"
                label="ZIP"
                name="zipCode"
                value={_billingData.zipCode}
                error={errors.zipCode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                value={_billingData.address}
                error={errors.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                type="email"
                variant="outlined"
                margin="none"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={_billingData.email}
                error={errors.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                value={_billingData.phone}
                error={errors.phone}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={4} xs={4} />
            <Grid item md={4} xs={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Save Data
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    billingData: state.billingData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadBillingData: bindActionCreators(
        billingDataActions.loadBillingData,
        dispatch
      ),
      updateBillingData: bindActionCreators(
        billingDataActions.updateBillingData,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingDataPage);
