import * as types from "../actions/actionTypes";
import * as contactApi from "../../api/contactApi";

export function loadContactsSuccess(contacts) {
  return { type: types.GET_CONTACTS_SUCCESS, contacts };
}

export function loadContacts() {
  return function(dispatch) {
    return contactApi.getContacts().then(contacts => {
      dispatch(loadContactsSuccess(contacts));
    });
  };
}

export function updateContactSuccess(contact) {
  return { type: types.UPDATE_CONTACT_SUCCESS, contact };
}

export function updateContact(contact) {
  return function(dispatch) {
    return contactApi.updateContact(contact).then(() => {
      dispatch(updateContactSuccess(contact));
    });
  };
}

export function createContactSuccess(contact) {
  return { type: types.CREATE_CONTACT_SUCCESS, contact };
}

export function createContact(contact) {
  return function(dispatch) {
    return contactApi.createContact(contact).then(contact => {
      dispatch(createContactSuccess(contact));
    });
  };
}

export function deleteContactSuccess(contact) {
  return { type: types.DELETE_CONTACT_SUCCESS, contact };
}

export function deleteContact(contact) {
  return function(dispatch) {
    return contactApi.deleteContact(contact).then(() => {
      dispatch(deleteContactSuccess(contact));
    });
  };
}
