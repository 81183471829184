import React, { useState, useContext } from "react";
import AuthContext from "../../AuthContext";
import { Redirect, NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import NotificationsContext from "../../NotificationsContext";
import logo from "../../assets/logo_no_text.png";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  navLink: {
    color: "#3f51b5",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  logo: {
    marginBottom: "24px",
  },
}));

const LoginPage = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    username: "",
    password: "",
    domain: "",
  });
  const [errors, setErrors] = useState({
    username: false,
    password: false,
    domain: false,
  });
  const notifications = useContext(NotificationsContext);

  const handleChange = (event) => {
    setErrors({
      ...errors,
      [event.target.name]: event.target.required && !event.target.value,
    });
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const submitLogin = (event, auth) => {
    event.preventDefault();
    let formIsValid = true;
    if (!state.username) {
      errors.username = true;
      formIsValid = false;
    }
    if (!state.password) {
      errors.password = true;
      formIsValid = false;
    }
    if (!state.domain) {
      errors.domain = true;
      formIsValid = false;
    }
    if (!formIsValid) {
      notifications.showError("Please fill in all the mandatory fields!");
      return;
    }
    auth
      .attemptLogin(state.username, state.password, state.domain)
      .then((response) => {
        if (response.ok && !response.redirected) {
          auth.registerSuccessfulLogin(state.username, state.domain);
          auth.getUserData().then((userData) => {
            auth.setStoredUserData(userData);
          });
        } else {
          notifications.showError("Login failed - please try again!");
        }
      })
      .catch(() => {
        notifications.showError("Login failed - please try again!");
      });
  };

  return (
    <AuthContext.Consumer>
      {(auth) => {
        return auth.isLoggedIn() ? (
          <Redirect to="/" />
        ) : (
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <img src={logo} alt="MGComm" className={classes.logo} />
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <form
                className={classes.form}
                onSubmit={(event) => submitLogin(event, auth)}
                noValidate
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="domain"
                  label="Domain"
                  name="domain"
                  error={errors.domain}
                  value={state.domain}
                  onChange={handleChange}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  error={errors.username}
                  value={state.username}
                  onChange={handleChange}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  error={errors.password}
                  value={state.password}
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign In
                </Button>
                <Grid container>
                  {/* <Grid item xs>
                    <Link href="#">Forgot password?</Link>
                  </Grid> */}
                  <Grid item>
                    <NavLink to="/signup" className={classes.navLink}>
                      {"Don't have an account? Sign Up"}
                    </NavLink>
                  </Grid>
                </Grid>
              </form>
            </div>
            <Box mt={8}>
              <Typography color="textSecondary" align="center">
                {"Copyright © "}
                MGComm {new Date().getFullYear()}
                {"."}
              </Typography>
            </Box>
          </Container>
        );
      }}
    </AuthContext.Consumer>
  );
};

export default LoginPage;
