import { API_BASE_URI, jsonBasedFetch } from "./apiUtils";

export function getBillingData() {
  return jsonBasedFetch(`${API_BASE_URI}/users/me/billingData`);
}

export function updateBillingData(billingData) {
  return jsonBasedFetch(`${API_BASE_URI}/users/me/billingData`, {
    method: "PUT",
    body: JSON.stringify(billingData)
  });
}
