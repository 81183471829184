import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function contactReducer(state = initialState.contacts, action) {
  switch (action.type) {
    case types.CREATE_CONTACT_SUCCESS:
      return state ? [...state, { ...action.contact }] : null;
    case types.UPDATE_CONTACT_SUCCESS:
      return state.map(contact =>
        contact.id === action.contact.id ? action.contact : contact
      );
    case types.DELETE_CONTACT_SUCCESS:
      return state.filter(contact => contact.id !== action.contact.id);
    case types.GET_CONTACTS_SUCCESS:
      return [...action.contacts];
    default:
      return state;
  }
}
