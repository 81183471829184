import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function paymentReducer(state = initialState.payments, action) {
  switch (action.type) {
    case types.CREATE_PAYMENT_SUCCESS:
      return [...state, { ...action.payment }];
    case types.GET_PAYMENTS_SUCCESS:
      return [...action.payments];
    default:
      return state;
  }
}
