import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as tariffActions from "../../redux/actions/tariffActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead
} from "@material-ui/core";
import Loading from "../common/Loading";

const useStyles = makeStyles(theme => ({
  lettersContainer: {
    listStyle: "none",
    textAlign: "center"
  },
  letter: {
    display: "inline-block",
    margin: "0 4px",
    fontSize: "22px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light
    }
  },
  root: {
    width: "100%"
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "60%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    background: "rgba(0,0,0,0.04)"
  },
  table: {
    minWidth: 650
  },
  tableCell: {
    textAlign: "center"
  }
}));

const TariffListPage = ({ tariff, actions }) => {
  if (!tariff) actions.loadTariff().catch(error => {});
  // const letters = [
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "E",
  //   "F",
  //   "G",
  //   "H",
  //   "I",
  //   "J",
  //   "K",
  //   "L",
  //   "M",
  //   "N",
  //   "O",
  //   "P",
  //   "Q",
  //   "R",
  //   "S",
  //   "T",
  //   "U",
  //   "V",
  //   "W",
  //   "X",
  //   "Y",
  //   "Z"
  // ];
  const classes = useStyles();
  const countryMap = {
    "202": "Greece",
    "204": "Netherlands",
    "206": "Belgium",
    "208": "France",
    "212": "Monaco",
    "213": "Andorra",
    "214": "Spain",
    "216": "Hungary",
    "218": "Bosnia &amp; Herzegov.",
    "219": "Croatia",
    "220": "Serbia ",
    "222": "Italy",
    "226": "Romania",
    "228": "Switzerland",
    "230": "Czech Rep.",
    "231": "Slovakia",
    "232": "Austria",
    "234": "United Kingdom",
    "235": "United Kingdom",
    "238": "Denmark",
    "240": "Sweden",
    "242": "Norway",
    "244": "Finland",
    "246": "Lithuania",
    "247": "Latvia",
    "248": "Estonia",
    "250": "Russian Federation",
    "255": "Ukraine",
    "257": "Belarus",
    "259": "Moldova",
    "260": "Poland",
    "262": "Germany",
    "266": "Gibraltar",
    "268": "Portugal",
    "270": "Luxembourg",
    "272": "Ireland",
    "274": "Iceland",
    "276": "Albania",
    "278": "Malta",
    "280": "Cyprus",
    "282": "Georgia",
    "283": "Armenia",
    "284": "Bulgaria",
    "286": "Turkey",
    "288": "Faroe Islands",
    "289": "Abkhazia",
    "290": "Greenland",
    "292": "San Marino",
    "293": "Slovenia",
    "294": "Macedonia",
    "295": "Liechtenstein",
    "297": "Montenegro",
    "302": "Canada",
    "308": "St. Pierre &amp; Miquelon",
    "310": "United States",
    "311": "United States",
    "312": "United States",
    "316": "United States",
    "330": "Puerto Rico",
    "334": "Mexico",
    "338": "Jamaica",
    "340": "Martinique (French Department of)",
    "342": "Barbados",
    "344": "Antigua and Barbuda",
    "346": "Cayman Islands",
    "348": "British Virgin Islands",
    "350": "Bermuda",
    "352": "Grenada",
    "354": "Montserrat",
    "356": "Saint Kitts and Nevis",
    "358": "Saint Lucia",
    "360": "St. Vincent &amp; Gren.",
    "362": "Netherlands Antilles",
    "363": "Aruba",
    "364": "Bahamas",
    "365": "anguilla",
    "366": "Dominica",
    "368": "Cuba",
    "370": "Dominican Republic",
    "372": "Haiti",
    "374": "Trinidad and Tobago",
    "376": "Virgin Islands, US",
    "400": "Azerbaijan",
    "401": "Kazakhstan",
    "402": "Bhutan",
    "404": "India",
    "405": "India",
    "410": "Pakistan",
    "412": "Afghanistan",
    "413": "Sri Lanka",
    "414": "Myanmar (Burma)",
    "415": "Lebanon",
    "416": "Jordan",
    "417": "Syrian Arab Republic",
    "418": "Iraq",
    "419": "Kuwait",
    "420": "Saudi Arabia",
    "421": "yemen",
    "422": "Oman",
    "424": "United Arab Emirates",
    "425": "Palestinian Territory",
    "426": "Bahrain",
    "427": "Qatar",
    "428": "Mongolia",
    "429": "Nepal",
    "430": "United Arab Emirates",
    "431": "United Arab Emirates",
    "432": "Iran ",
    "434": "uzbekistan",
    "436": "Tajikistan",
    "437": "Kyrgyzstan",
    "438": "Turkmenistan",
    "440": "Japan",
    "441": "Japan",
    "450": "Korea S, Republic of",
    "452": "Viet Nam",
    "454": "Hongkong, China",
    "455": "Macao, China",
    "456": "Cambodia",
    "457": "Laos P.D.R.",
    "460": "China",
    "466": "Taiwan",
    "467": "Korea N., Dem. People's Rep.",
    "470": "Bangladesh",
    "472": "Maldives",
    "502": "Malaysia",
    "505": "Australia",
    "510": "Indonesia",
    "514": "Timor-Leste",
    "515": "Philippines",
    "520": "Thailand",
    "525": "Singapore",
    "528": "Brunei Darussalam",
    "530": "New Zealand",
    "537": "Papua New Guinea",
    "539": "Tonga",
    "540": "Solomon Islands",
    "541": "vanuatu",
    "542": "Fiji",
    "544": "American Samoa",
    "545": "Kiribati",
    "546": "New Caledonia",
    "547": "French Polynesia",
    "548": "Cook Islands",
    "549": "Samoa",
    "550": "Micronesia",
    "552": "Palau (Republic of)",
    "553": "Tuvalu",
    "555": "Niue",
    "602": "Egypt",
    "603": "algeria",
    "604": "Morocco",
    "605": "Tunisia",
    "606": "Libya",
    "607": "Gambia",
    "608": "Senegal",
    "609": "Mauritania",
    "610": "Mali",
    "611": "Guinea",
    "612": "Ivory Coast",
    "613": "Burkina Faso",
    "614": "Niger",
    "615": "Togo",
    "616": "Benin",
    "617": "Mauritius",
    "618": "Liberia",
    "619": "Sierra Leone",
    "620": "Ghana",
    "621": "Nigeria",
    "622": "Chad",
    "623": "Central African Rep.",
    "624": "Cameroon",
    "625": "Cape Verde",
    "626": "Sao Tome &amp; Principe",
    "627": "Equatorial Guinea",
    "628": "Gabon",
    "629": "Congo, Republic",
    "630": "Congo, Dem. Rep.",
    "631": "angola",
    "632": "Guinea-Bissau",
    "633": "Seychelles",
    "634": "Sudan",
    "635": "Rwanda",
    "636": "Ethiopia",
    "637": "Somalia",
    "638": "Djibouti",
    "639": "Kenya",
    "640": "Tanzania",
    "641": "Uganda",
    "642": "Burundi",
    "643": "Mozambique",
    "645": "Zambia",
    "646": "Madagascar",
    "647": "Reunion",
    "648": "Zimbabwe",
    "649": "Namibia",
    "650": "Malawi",
    "651": "Lesotho",
    "652": "Botswana",
    "653": "Swaziland",
    "654": "Comoros",
    "655": "South Africa",
    "657": "Eritrea",
    "659": "South Sudan (Republic of)",
    "702": "Belize",
    "704": "Guatemala",
    "706": "El Salvador",
    "708": "Honduras",
    "710": "Nicaragua",
    "712": "Costa Rica",
    "714": "Panama",
    "716": "Peru",
    "722": "Argentina Republic",
    "724": "Brazil",
    "730": "Chile",
    "732": "Colombia",
    "734": "Venezuela",
    "736": "Bolivia",
    "738": "Guyana",
    "740": "Ecuador",
    "744": "Paraguay",
    "746": "Suriname",
    "748": "Uruguay",
    "750": "Falkland Islands (Malvinas)",
    "901": "Satellite Networks"
  };
  return (
    <>
      {/* <ul className={classes.lettersContainer}>
        {letters.map(letter => (
          <li
            className={classes.letter}
            key={letter}
            onClick={event => {
              alert(letter);
            }}
          >
            {letter}
          </li>
        ))}
      </ul> */}
      {!tariff && <Loading />}
      {tariff && (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} align="right">
                    Country
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    MCC
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    MNC
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Price
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tariff.map(row => (
                  <TableRow key={`${row.mcc}${row.mnc}`}>
                    <TableCell className={classes.tableCell} align="right">
                      {countryMap[row.mcc]}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {row.mcc}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {row.mnc}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {row.smsCost} EUR
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    tariff: state.tariff
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadTariff: bindActionCreators(tariffActions.loadTariff, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TariffListPage);
