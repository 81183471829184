import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import * as contactActions from "../../redux/actions/contactActions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import "./ContactsPage.css";
import {
  Typography,
  Container,
  Grid,
  TextField,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../common/Loading";
import NotificationsContext from "../../NotificationsContext";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const EditContactsPage = ({ contacts, actions, history, match }) => {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    msisdn: ""
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    msisdn: false
  });
  const id = parseInt(match.params.id);
  const classes = useStyles();
  const notifications = useContext(NotificationsContext);

  useEffect(() => {
    if (!contacts) {
      actions.loadContacts();
    } else {
      const _contact = contacts.filter(
        x => x.id === parseInt(match.params.id)
      )[0];
      setContact({
        name: _contact.name,
        email: _contact.email,
        msisdn: _contact.msisdn
      });
    }
  }, [contacts, actions, match.params.id]);

  const handleChange = event => {
    setErrors({
      ...errors,
      [event.target.name]: event.target.required && !event.target.value
    });
    setContact({
      ...contact,
      [event.target.name]: event.target.value
    });
  };

  const goToContacts = () => {
    history.push("/contacts");
  };

  const saveData = event => {
    event.preventDefault();

    let formIsValid = true;
    const errorsCopy = { ...errors };
    for (const key in contact) {
      if (!contact[key]) {
        errorsCopy[key] = true;
        formIsValid = false;
      }
    }
    setErrors({
      ...errorsCopy
    });
    if (!formIsValid) {
      notifications.showError("Please fill in all mandatory fields!");
      return;
    }
    actions
      .updateContact({ ...contact, id })
      .then(response => {
        notifications.showSuccess("Contact Updated successfully!");
        history.push("/contacts");
      })
      .catch(error => {
        notifications.showError("Contact Update failed!");
      });
  };

  return contact ? (
    <>
      <Container component="main" maxWidth="sm" style={{ paddingTop: "24px" }}>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Edit Contact
          </Typography>
          <form
            className={classes.form}
            onSubmit={event => saveData(event)}
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="name"
                  label="First Name"
                  name="name"
                  value={contact.name}
                  error={errors.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="msisdn"
                  label="MSISDN"
                  name="msisdn"
                  value={contact.msisdn}
                  error={errors.msisdn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={contact.email}
                  error={errors.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <Button
                  onClick={event => goToContacts()}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  ) : (
    <Loading />
  );
};

EditContactsPage.propTypes = {
  contacts: PropTypes.array,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    contacts: state.contacts
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadContacts: bindActionCreators(contactActions.loadContacts, dispatch),
      updateContact: bindActionCreators(contactActions.updateContact, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditContactsPage);
