import * as types from "../actions/actionTypes";
import * as paymentsApi from "../../api/paymentsApi";

export function loadPaymentsSuccess(payments) {
  return { type: types.GET_PAYMENTS_SUCCESS, payments };
}

export function paymentsCreatedSuccess(payment) {
  return { type: types.CREATE_PAYMENT_SUCCESS, payment };
}

export function paymentsConfirmationSuccess() {
  return { type: types.CONFIRM_PAYMENT_SUCCESS };
}

export function loadPayments() {
  return function(dispatch) {
    return paymentsApi.getPayments().then(payments => {
      dispatch(loadPaymentsSuccess(payments));
    });
  };
}

export function createPayment(amount, currency) {
  const baseUrl = window.location.origin;
  return function(dispatch) {
    return paymentsApi
      .createPayment(
        amount,
        currency,
        `${baseUrl}/payments/created`,
        `${baseUrl}/payments/cancelled`
      )
      .then(response => {
        dispatch(paymentsCreatedSuccess(response));
      });
  };
}

export function confirmPayment(id) {
  return function(dispatch) {
    return paymentsApi.confirmPayment(id).then(response => {
      dispatch(paymentsConfirmationSuccess(response));
    });
  };
}
