import auth from "../auth/Auth";
import saveAs from "file-saver";

// export const API_BASE_URI = "http://localhost:8080";
// export const API_BASE_URI = "http://localhost:8080/smsapi";
export const API_BASE_URI = "portalapi";

export const jsonBasedFetch = (...args) => {
  if (args.length === 1) {
    args.push({});
  }
  if (args[1].headers) {
    if (!args[1].headers["Content-Type"])
      args[1].headers["Content-Type"] = "application/json";
    if (!args[1].headers["Accept"])
      args[1].headers["Accept"] = "application/json";
  } else {
    args[1].headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }
  return fetch(...args)
    .then((response) => {
      if (response.ok && response.redirected) {
        auth.logout();
      }
      return response;
    })
    .then((response) => response.json())
    .then((responseObject) => {
      if (responseObject.status) {
        throw new Error(responseObject);
      }
      return responseObject;
    });
};

export const csvFetch = (...args) => {
  if (args.length === 1) {
    args.push({});
  }
  if (args[1].headers) {
    if (!args[1].headers["Content-Type"])
      args[1].headers["Content-Type"] = "application/json";
    if (!args[1].headers["Accept"]) args[1].headers["Accept"] = "text/csv";
  } else {
    args[1].headers = {
      "Content-Type": "application/json",
      Accept: "text/csv",
    };
  }
  args[1].contentType = "blob";
  return fetch(...args)
    .then((response) => {
      if (response.ok && response.redirected) {
        auth.logout();
      }
      return response;
    })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, "sms_map_report.csv"));
};
