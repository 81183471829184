import React from "react";
import { CircularProgress, Container } from "@material-ui/core";

const Loading = () => {
  return (
    <Container
      component="main"
      maxWidth="sm"
      align="center"
      style={{ paddingTop: "48px" }}
    >
      <CircularProgress />
    </Container>
  );
};

export default Loading;
