export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";

export const GET_TARIFF_SUCCESS = "GET_TARIFF_SUCCESS";

export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_SUCCESS = "CONFIRM_PAYMENT_SUCCESS";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";

export const UPDATE_ACCOUNT_DATA = "UPDATE_ACCOUNT_DATA";
export const UPDATE_ACCOUNT_DATA_SUCCESS = "UPDATE_ACCOUNT_DATA_SUCCESS";
export const GET_ACCOUNT_DATA = "GET_ACCOUNT_DATA";
export const GET_ACCOUNT_DATA_SUCCESS = "GET_ACCOUNT_DATA_SUCCESS";

export const UPDATE_BILLING_DATA = "UPDATE_BILLING_DATA";
export const UPDATE_BILLING_DATA_SUCCESS = "UPDATE_BILLING_DATA_SUCCESS";
export const GET_BILLING_DATA = "GET_BILLING_DATA";
export const GET_BILLING_DATA_SUCCESS = "GET_BILLING_DATA_SUCCESS";

export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_BULK_SUCCESS = "SEND_SMS_BULK_SUCCESS";
export const SEND_SMS_BULK_WITH_CONTENT_SUCCESS =
  "SEND_SMS_BULK_WITH_CONTENT_SUCCESS";

export const SMS_HISTORY_SUCCESS = "SMS_HISTORY_SUCCESS";
