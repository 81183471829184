// import { handleResponse, handleError } from "./apiUtils";

import {
  USER_NAME_SESSION_ATTRIBUTE_NAME,
  DOMAIN_SESSION_ATTRIBUTE_NAME
} from "../auth/Auth";

import { API_BASE_URI, jsonBasedFetch } from "./apiUtils";

export function getAccountData() {
  const username = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
  const domain = sessionStorage.getItem(DOMAIN_SESSION_ATTRIBUTE_NAME);

  return jsonBasedFetch(`${API_BASE_URI}/users/${domain}/${username}`);
}

export function updateAccountData(accountData) {
  const username = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
  const domain = sessionStorage.getItem(DOMAIN_SESSION_ATTRIBUTE_NAME);
  return jsonBasedFetch(`${API_BASE_URI}/users/${domain}/${username}`, {
    method: "PUT",
    body: JSON.stringify({
      firstName: accountData.firstName,
      lastName: accountData.lastName,
      email: accountData.email
    })
  });
}
