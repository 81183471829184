import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function contactReducer(state = initialState.tariff, action) {
  switch (action.type) {
    case types.GET_TARIFF_SUCCESS:
      return (action.tariff && action.tariff.costs) || [];
    default:
      return state;
  }
}
