import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function billingDataReducer(
  state = initialState.billingData,
  action
) {
  switch (action.type) {
    case types.UPDATE_BILLING_DATA_SUCCESS:
      return { ...action.billingData };
    case types.GET_BILLING_DATA_SUCCESS:
      return { ...action.billingData };
    default:
      return state;
  }
}
