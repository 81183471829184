import * as types from "../actions/actionTypes";
import * as tariffApi from "../../api/tariffApi";

export function loadTariffSuccess(tariff) {
  return { type: types.GET_TARIFF_SUCCESS, tariff };
}

export function loadTariff() {
  return function(dispatch) {
    return tariffApi.getTariff().then(tariff => {
      dispatch(loadTariffSuccess(tariff));
    });
  };
}
