// import { handleResponse, handleError } from "./apiUtils";

import { API_BASE_URI, jsonBasedFetch } from "./apiUtils";

export function sendSms(sms) {
  return jsonBasedFetch(`${API_BASE_URI}/sms/`, {
    method: "POST",
    body: JSON.stringify(sms),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
}

export function sendBulkSms(smsBulk) {
  return jsonBasedFetch(`${API_BASE_URI}/sms/bulk`, {
    method: "POST",
    body: JSON.stringify(smsBulk)
  });
}

export function sendBulkSmsWithMessage(smsBulkWithMessage) {
  return jsonBasedFetch(`${API_BASE_URI}/sms/multiple`, {
    method: "POST",
    body: JSON.stringify(smsBulkWithMessage)
  });
}
