import { combineReducers } from "redux";
import contactReducer from "./contactReducer";
import accountDataReducer from "./accountDataReducer";
import paymentReducer from "./paymentReducer";
import billingDataReducer from "./billingDataReducer";
import tariffReducer from "./tariffReducer";
import initialState from "./initialState";
import smsHistoryReducer from "./smsHistoryReducer";

const appReducer = combineReducers({
  contacts: contactReducer,
  accountData: accountDataReducer,
  payments: paymentReducer,
  billingData: billingDataReducer,
  tariff: tariffReducer,
  smsHistory: smsHistoryReducer
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = initialState;
  }

  return appReducer(state, action);
};

export default rootReducer;
