import * as types from "../actions/actionTypes";
import * as smsApi from "../../api/smsApi";

export function sendSmsSuccess(sms) {
  return { type: types.SEND_SMS_SUCCESS, sms };
}

export function sendBulkSmsSuccess(smsBulk) {
  return { type: types.SEND_SMS_BULK_SUCCESS, smsBulk };
}

export function sendBulkSmsWithMessageSuccess(smsBulk) {
  return { type: types.SEND_SMS_BULK_WITH_CONTENT_SUCCESS, smsBulk };
}

export function sendSms(sms) {
  return function(dispatch) {
    return smsApi.sendSms(sms);
  };
}

export function sendBulkSms(smsBulk) {
  return function(dispatch) {
    return smsApi.sendBulkSms(smsBulk).then(tariff => {
      dispatch(sendBulkSmsSuccess(smsBulk));
    });
  };
}

export function sendBulkSmsWithMessage(smsBulkWithMessage) {
  return function(dispatch) {
    return smsApi.sendBulkSmsWithMessage(smsBulkWithMessage).then(tariff => {
      dispatch(sendBulkSmsWithMessageSuccess(smsBulkWithMessage));
    });
  };
}
