import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import * as contactActions from "../../redux/actions/contactActions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import "./ContactsPage.css";
import {
  Typography,
  Container,
  Grid,
  TextField,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsContext from "../../NotificationsContext";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const CreateContactsPage = ({ actions, history }) => {
  const [contact, setContact] = useState({
    name: "",
    msisdn: "",
    email: ""
  });
  const [errors, setErrors] = useState({
    name: false,
    msisdn: false,
    email: false
  });
  const classes = useStyles();
  const notifications = useContext(NotificationsContext);
  const handleChange = event => {
    setErrors({
      ...errors,
      [event.target.name]: event.target.required && !event.target.value
    });
    setContact({
      ...contact,
      [event.target.name]: event.target.value
    });
  };

  const saveData = event => {
    event.preventDefault();

    let formIsValid = true;
    const errorsCopy = { ...errors };
    for (const key in contact) {
      if (!contact[key]) {
        errorsCopy[key] = true;
        formIsValid = false;
      }
    }
    setErrors({
      ...errorsCopy
    });
    if (!formIsValid) {
      notifications.showError("Please fill in all mandatory fields!");
      return;
    }
    actions
      .createContact(contact)
      .then(response => {
        notifications.showSuccess("Contact created!");
        history.push("/contacts");
      })
      .catch(error => {
        notifications.showError("Contact creation failed!");
      });
  };

  const goToContacts = () => {
    history.push("/contacts");
  };

  return (
    <>
      <Container component="main" maxWidth="sm" style={{ paddingTop: "24px" }}>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Create Contact
          </Typography>
          <form
            className={classes.form}
            onSubmit={event => saveData(event)}
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  value={contact.name}
                  error={errors.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="msisdn"
                  label="MSISDN"
                  name="msisdn"
                  value={contact.msisdn}
                  error={errors.msisdn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="none"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={contact.email}
                  error={errors.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <Button
                  onClick={event => goToContacts()}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};

CreateContactsPage.propTypes = {
  contacts: PropTypes.array,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createContact: bindActionCreators(contactActions.createContact, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateContactsPage);
