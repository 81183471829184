import * as types from "./actionTypes";
import * as billingDataApi from "../../api/billingDataApi";

export function loadBillingDataSuccess(billingData) {
  return { type: types.GET_BILLING_DATA_SUCCESS, billingData };
}

export function loadBillingData() {
  return function(dispatch) {
    return billingDataApi.getBillingData().then(billingData => {
      dispatch(loadBillingDataSuccess(billingData));
    });
  };
}

export function updateBillingDataSuccess(billingData) {
  return { type: types.UPDATE_BILLING_DATA_SUCCESS, billingData };
}

export function updateBillingData(billingData) {
  return function(dispatch) {
    return billingDataApi.updateBillingData(billingData).then(response => {
      if (response.status === "ok") {
        dispatch(updateBillingDataSuccess(billingData));
      }
    });
  };
}
