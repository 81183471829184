import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as contactActions from "../../redux/actions/contactActions";
import * as smsActions from "../../redux/actions/smsActions";
import * as smsHistoryActions from "../../redux/actions/smsHistoryActions";
import * as accountDataActions from "../../redux/actions/accountDataActions";
import queryString from "querystring";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../common/TabPanel";
import NotificationContext from "../../NotificationsContext";
import DataTable from "../common/DataTable";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Popover,
  IconButton,
  FormControl,
  Radio,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import InfoIcon from "@material-ui/icons/Info";
import Select from "react-select";
import CSVReader from "react-csv-reader";
import clsx from "clsx";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  navLink: {
    color: "#3f51b5",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  infoPopover: {
    padding: theme.spacing(2),
  },
  contactsSelect: {
    zIndex: 100,
  },
  selectError: {
    "& *": {
      borderColor: "red !important",
    },
  },
}));

const SMSPage = ({
  location,
  history,
  contacts,
  smsHistoryRecords,
  accountData,
  actions,
}) => {
  let now = new Date();
  let oneWeekInThePast = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
  const initialMessage = {
    from: "",
    to: "",
    content: "",
    alphabet: "gsm7",
  };
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [csvData, setCSVData] = useState(null);
  const [receiverType, setReceiverType] = useState("MSISDN");
  const [message, setMessage] = useState(initialMessage);
  const notifications = useContext(NotificationContext);
  const [infoPopoverAnchorEl, setInfoPopoverAnchorEl] = useState(null);
  const [infoPopoverOpened, setInfoPopoverOpened] = useState(false);
  const [toInfoPopoverAnchorEl, setToInfoPopoverAnchorEl] = useState(null);
  const [toInfoPopoverOpened, setToInfoPopoverOpened] = useState(false);
  const [fromInfoPopoverAnchorEl, setFromInfoPopoverAnchorEl] = useState(null);
  const [fromInfoPopoverOpened, setFromInfoPopoverOpened] = useState(false);
  const [fromDate, setFromDate] = useState(oneWeekInThePast);
  const [toDate, setToDate] = useState(now);
  const [errors, setErrors] = useState({
    from: false,
    to: false,
    content: false,
  });
  let historyRecords = [];
  if (!smsHistoryRecords) {
    actions.loadSmsHistoryRecords(fromDate, toDate);
  } else {
    historyRecords = smsHistoryRecords;
  }
  if (!accountData) {
    actions.loadAccountData(fromDate, toDate);
  }

  const handleCSVParserClick = (event) => {
    if (event.target.type === "file") {
      setCSVData(null);
    }
  };

  const openPopover = (event) => {
    setInfoPopoverAnchorEl(event.currentTarget);
    setInfoPopoverOpened(true);
  };

  const closeInfoPopover = () => {
    setInfoPopoverAnchorEl(null);
    setInfoPopoverOpened(false);
  };

  const openToInfoPopover = (event) => {
    setToInfoPopoverAnchorEl(event.currentTarget);
    setToInfoPopoverOpened(true);
  };

  const closeToInfoPopover = () => {
    setToInfoPopoverAnchorEl(null);
    setToInfoPopoverOpened(false);
  };

  const openFromInfoPopover = (event) => {
    setFromInfoPopoverAnchorEl(event.currentTarget);
    setFromInfoPopoverOpened(true);
  };

  const closeFromInfoPopover = () => {
    setFromInfoPopoverAnchorEl(null);
    setFromInfoPopoverOpened(false);
  };

  if (!contacts) actions.loadContacts().catch((error) => {});

  const params = queryString.parse(location.search);
  const section = params.section || params["?section"];
  if (section) {
    if (value !== parseInt(section)) {
      setValue(parseInt(section));
    }
  }

  const handleReceiverTypeChange = (event) => {
    setReceiverType(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    if (value !== newValue) {
      setMessage(initialMessage);
      setContact(null);
      setCSVData(null);
      setInfoPopoverOpened(false);
      setInfoPopoverAnchorEl(null);
    }
    history.push(`/sms?section=${newValue}`);
  };

  const detectAlphabet = (text) => {
    let characterCodesAboveGSM7 = text
      .split("")
      .map(function (letter) {
        return letter.charCodeAt(0);
      })
      .filter(function (code) {
        return code > 127;
      });

    if (characterCodesAboveGSM7.length > 0) {
      let characterCodesAboveGSM8 = characterCodesAboveGSM7.filter(function (
        code
      ) {
        return code > 255;
      });
      if (characterCodesAboveGSM8.length > 0) {
        return "ucs2";
      } else {
        return "gsm8";
      }
    } else {
      return "gsm7";
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "content") {
      const alphabet = detectAlphabet(event.target.value);
      setMessage({
        ...message,
        [event.target.name]: event.target.value,
        alphabet,
      });
    } else {
      setMessage({
        ...message,
        [event.target.name]: event.target.value,
      });
    }
    setErrors({
      ...errors,
      [event.target.name]: event.target.required && !event.target.value,
    });
  };

  const detectIfSenderIsAlpha = (text) => {
    const lettersInFrom = text
      .split("")
      .map(function (letter) {
        return letter.charCodeAt(0);
      })
      .filter(function (code) {
        return code < 48 || code > 57;
      });
    return lettersInFrom.length > 0;
  };

  const sendBulkSms = (event) => {
    event.preventDefault();
    let formValid = true;
    if (!message.from) {
      errors.from = true;
      formValid = false;
    }
    if (!message.content) {
      errors.content = true;
      formValid = false;
    }

    if (!formValid) {
      notifications.showError("Please enter all fields!");
      return;
    }
    if (!csvData || !csvData.length) {
      notifications.showError("CSV File missing!");
      return;
    }
    const alpha = detectIfSenderIsAlpha(message.from);

    const sms = {
      senderId: message.from,
      senderType: alpha ? "ALPHA" : "INTERNATIONAL",
      message: message.content,
      messageAlphabet: message.alphabet,
      destinations: csvData.map((dataEntry) => dataEntry.msisdn),
    };

    actions
      .sendBulkSms(sms)
      .then((response) => {
        notifications.showSuccess("SMS has been queued for sending!");
        setMessage(initialMessage);
      })
      .catch((error) => {
        notifications.showError(
          "Sending SMS has failed - please try again later!"
        );
      });
  };

  const sendBulkSmsWithMessage = (event) => {
    event.preventDefault();
    if (!message.from) {
      errors.from = true;
      notifications.showError("Please enter all fields!");
      return;
    }

    if (!csvData || !csvData.length) {
      notifications.showError("CSV File missing!");
      return;
    }
    const alpha = detectIfSenderIsAlpha(message.from);

    const sms = {
      senderId: message.from,
      senderType: alpha ? "ALPHA" : "INTERNATIONAL",
      messages: csvData.map((dataEntry) => dataEntry.message),
      messageAlphabets: csvData
        .map((dataEntry) => dataEntry.message)
        .map(detectAlphabet),
      destinations: csvData.map((dataEntry) => dataEntry.msisdn),
    };

    actions
      .sendBulkSmsWithMessage(sms)
      .then((response) => {
        notifications.showSuccess("SMS has been queued for sending!");
        setMessage(initialMessage);
      })
      .catch((error) => {
        notifications.showError(
          "Sending SMS has failed - please try again later!"
        );
      });
  };

  const sendSms = (event) => {
    event.preventDefault();
    const alpha = detectIfSenderIsAlpha(message.from);
    let formValid = true;
    if (!message.from) {
      errors.from = true;
      formValid = false;
    }
    if (!message.to) {
      if (!contact) {
        errors.to = true;
        formValid = false;
      }
    }
    if (!message.content) {
      errors.content = true;
      formValid = false;
    }

    if (!formValid) {
      notifications.showError("Please enter all fields!");
      return;
    }

    const sms = {
      senderId: message.from,
      senderType: alpha ? "ALPHA" : "INTERNATIONAL",
      message: message.content,
      messageAlphabet: message.alphabet,
    };

    if (receiverType === "MSISDN") {
      sms.destination = message.to;
    } else {
      const filteredContacts = contacts.filter((c) => c.id === contact.value);
      const contactDto = filteredContacts.length && filteredContacts[0];
      sms.contactDTO = {
        msisdn: contactDto.msisdn,
      };
    }

    actions
      .sendSms(sms)
      .then((response) => {
        notifications.showSuccess("SMS has been queued for sending!");
        setMessage(initialMessage);
        setContact(null);
      })
      .catch((error) => {
        notifications.showError(
          "Sending SMS has failed - please try again later!"
        );
      });
  };

  const reloadHistoryRecords = (event) => {
    const thirtyDaysInMS = 30 * 24 * 60 * 60 * 1000;
    if (toDate.getTime() - fromDate.getTime() < 0) {
      notifications.showError(
        "Invalid period - from date must be before to date!"
      );
      return;
    }

    if (toDate.getTime() - fromDate.getTime() > thirtyDaysInMS) {
      notifications.showError("Period for this report can not exceed 30 days!");
      return;
    }

    actions.loadSmsHistoryRecords(fromDate, toDate);
  };

  const downloadDetailedReport = (event) => {
    actions.getDetailedReport(fromDate, toDate);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const csvParsed = (data) => {
    setCSVData(data);
  };
  const theme = useTheme();
  const [contact, setContact] = React.useState(null);

  const handleContactChange = (value) => {
    errors.to = !value;
    setContact(value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const selectStyles = {
    input: (base) => ({
      ...base,
      padding: "11px",
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
      },
    }),
  };

  const historyCellDefinitions = [
    {
      id: 1,
      label: "Date",
      numeric: true,
      disablePadding: false,
      dataProp: "sendDateUTC",
      width: "300px",
    },
    {
      id: 2,
      label: "Recipient",
      numeric: true,
      disablePadding: false,
      dataProp: "to",
    },
    {
      id: 3,
      label: "Executed by",
      numeric: true,
      disablePadding: false,
      dataProp: "userId",
    },
    {
      id: 4,
      label: "Status",
      numeric: true,
      disablePadding: false,
      dataProp: "status",
    },
  ];
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab label="Send SMS" {...a11yProps(0)} />
          <Tab label="Send Bulk SMS" {...a11yProps(1)} />
          <Tab label="Send Bulk SMS with Content" {...a11yProps(2)} />
          <Tab label="History" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {value === 0 ? (
          <Container component="main" maxWidth="sm">
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Send SMS
              </Typography>
              <form
                className={classes.form}
                onSubmit={(event) => sendSms(event)}
                noValidate
              >
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      margin="none"
                      required
                      fullWidth
                      id="from"
                      label="From"
                      name="from"
                      value={message.from}
                      error={errors.from}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="Info"
                      onClick={openFromInfoPopover}
                      style={{ marginTop: "4px", marginLeft: "-8px" }}
                    >
                      <InfoIcon />
                    </IconButton>
                    <Popover
                      open={fromInfoPopoverOpened}
                      anchorEl={fromInfoPopoverAnchorEl}
                      onClose={closeFromInfoPopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography className={classes.infoPopover}>
                        The recipient of your message will see this value as the
                        sender id - it can be either random text or a phone
                        number.
                      </Typography>
                    </Popover>
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroup
                      aria-label="receiverType"
                      name="receiverType"
                      value={receiverType}
                      onChange={handleReceiverTypeChange}
                      row
                    >
                      <FormControlLabel
                        value="MSISDN"
                        control={<Radio color="primary" />}
                        label="MSISDN"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Contact"
                        control={<Radio color="primary" />}
                        label="Contact"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Grid>
                  {receiverType === "MSISDN" ? (
                    <>
                      <Grid item xs={11}>
                        <TextField
                          variant="outlined"
                          margin="none"
                          required
                          fullWidth
                          id="to"
                          label="To"
                          name="to"
                          error={errors.to}
                          value={message.to}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="Info"
                          onClick={openToInfoPopover}
                          style={{ marginTop: "4px", marginLeft: "-8px" }}
                        >
                          <InfoIcon />
                        </IconButton>
                        <Popover
                          open={toInfoPopoverOpened}
                          anchorEl={toInfoPopoverAnchorEl}
                          onClose={closeToInfoPopover}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Typography className={classes.infoPopover}>
                            Please enter the number in international format
                            without the plus (+) sign.
                          </Typography>
                        </Popover>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      {contacts ? (
                        <FormControl
                          className={clsx(classes.contactsSelect, {
                            [classes.selectError]: errors.to,
                          })}
                          fullWidth
                        >
                          <Select
                            styles={selectStyles}
                            inputId="react-select-contact"
                            TextFieldProps={{
                              label: "Contact",
                              InputLabelProps: {
                                htmlFor: "react-select-contact",
                                shrink: true,
                              },
                            }}
                            placeholder="Search your contacts"
                            options={contacts.map((contact) => {
                              return { label: contact.name, value: contact.id };
                            })}
                            // components={components}
                            value={contact}
                            onChange={handleContactChange}
                          />
                        </FormControl>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="none"
                      required
                      id="content"
                      label="Content"
                      name="content"
                      multiline
                      value={message.content}
                      error={errors.content}
                      onChange={handleChange}
                      rows={8}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="span">
                      Number of characters: {message.content.length}
                    </Typography>
                  </Grid>
                  {message.alphabet ? (
                    <Grid item xs={12}>
                      <Typography component="span">
                        Character limit:{" "}
                        {message.alphabet === "ucs2" ? 70 : 150}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        ) : (
          <></>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {value === 1 ? (
          <Container component="main" maxWidth="sm">
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Send Bulk SMS from CSV
              </Typography>
              <form
                className={classes.form}
                onSubmit={(event) => sendBulkSms(event)}
                noValidate
              >
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      margin="none"
                      required
                      fullWidth
                      id="from"
                      label="From"
                      name="from"
                      error={errors.from}
                      value={message.from}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="Info"
                      onClick={openFromInfoPopover}
                      style={{ marginTop: "4px", marginLeft: "-8px" }}
                    >
                      <InfoIcon />
                    </IconButton>
                    <Popover
                      open={fromInfoPopoverOpened}
                      anchorEl={fromInfoPopoverAnchorEl}
                      onClose={closeFromInfoPopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography className={classes.infoPopover}>
                        The recipient of your message will see this value as the
                        sender id - it can be either random text or a phone
                        number.
                      </Typography>
                    </Popover>
                  </Grid>
                  <Grid item xs={11} onClick={handleCSVParserClick}>
                    <CSVReader
                      cssClass="react-csv-input"
                      label="Select a CSV file that contains the recipients' MSISDNs"
                      onFileLoaded={csvParsed}
                      onError={(error, file) => {
                        console.log(error);
                        console.log(file);
                      }}
                      parserOptions={papaparseOptions}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="Info"
                      onClick={openPopover}
                      style={{ marginLeft: "-8px" }}
                    >
                      <InfoIcon />
                    </IconButton>
                    <Popover
                      open={infoPopoverOpened}
                      anchorEl={infoPopoverAnchorEl}
                      onClose={closeInfoPopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography className={classes.infoPopover}>
                        The csv file must contain one column named "msisdn".
                      </Typography>
                    </Popover>
                  </Grid>
                  {csvData ? (
                    <Grid item xs={12}>
                      <Typography component="span">
                        Number of MSISDNs: {csvData.length}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="none"
                      required
                      id="content"
                      label="Content"
                      name="content"
                      error={errors.content}
                      value={message.content}
                      onChange={handleChange}
                      rows={8}
                      multiline
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="span">
                      Number of characters: {message.content.length}
                    </Typography>
                  </Grid>
                  {message.alphabet ? (
                    <Grid item xs={12}>
                      <Typography component="span">
                        Character limit:{" "}
                        {message.alphabet === "ucs2" ? 70 : 150}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        ) : (
          <></>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {value === 2 ? (
          <Container component="main" maxWidth="sm">
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Send Bulk SMS from CSV
              </Typography>
              <form
                className={classes.form}
                onSubmit={(event) => sendBulkSmsWithMessage(event)}
                noValidate
              >
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      margin="none"
                      required
                      fullWidth
                      id="from"
                      label="From"
                      name="from"
                      value={message.from}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="Info"
                      onClick={openFromInfoPopover}
                      style={{ marginTop: "4px", marginLeft: "-8px" }}
                    >
                      <InfoIcon />
                    </IconButton>
                    <Popover
                      open={fromInfoPopoverOpened}
                      anchorEl={fromInfoPopoverAnchorEl}
                      onClose={closeFromInfoPopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography className={classes.infoPopover}>
                        The recipient of your message will see this value as the
                        sender id - it can be either random text or a phone
                        number.
                      </Typography>
                    </Popover>
                  </Grid>
                  <Grid item xs={11} onClick={handleCSVParserClick}>
                    <CSVReader
                      cssClass="react-csv-input"
                      label="Select a CSV file containing MSISDNs and respective message content"
                      onFileLoaded={csvParsed}
                      parserOptions={papaparseOptions}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="Info"
                      onClick={openPopover}
                      style={{ marginLeft: "-8px" }}
                    >
                      <InfoIcon />
                    </IconButton>
                    <Popover
                      open={infoPopoverOpened}
                      anchorEl={infoPopoverAnchorEl}
                      onClose={closeInfoPopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography className={classes.infoPopover}>
                        The csv file must contain two columns - "msisdn" and
                        "message".
                      </Typography>
                    </Popover>
                  </Grid>
                  {csvData ? (
                    <Grid item xs={12}>
                      <Typography component="span">
                        Number of Messages to be sent: {csvData.length}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        ) : (
          <></>
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {value === 3 ? (
          <>
            <div style={{ marginLeft: "32px" }}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                style={{ marginLeft: "32px" }}
              >
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="report-period-from"
                  label="From"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  style={{ marginRight: "16px" }}
                  KeyboardButtonProps={{
                    "aria-label": "Change from date",
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="report-period-to"
                  label="To"
                  value={toDate}
                  onChange={handleToDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "Change to date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <Button
                size="medium"
                color="primary"
                style={{
                  verticalAlign: "bottom",
                  marginBottom: "8px",
                  marginLeft: "32px",
                }}
                onClick={(event) => reloadHistoryRecords()}
              >
                Reload Data (Max 30 days)
              </Button>
              {accountData ? (
                <Button
                  size="medium"
                  color="primary"
                  style={{
                    verticalAlign: "bottom",
                    marginTop: "28px",
                    marginRight: "32px",
                    float: "right",
                  }}
                  onClick={(event) => downloadDetailedReport()}
                >
                  Download detailed Report in CSV
                </Button>
              ) : (
                <></>
              )}
            </div>
            <Container component="main" maxWidth="xl">
              <div className={classes.paper}>
                <DataTable
                  title="History"
                  tableCells={historyCellDefinitions}
                  tableData={historyRecords}
                />
              </div>
            </Container>
          </>
        ) : (
          <></>
        )}
      </TabPanel>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    contacts: state.contacts,
    smsHistoryRecords: state.smsHistory,
    accountData: state.accountData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadContacts: bindActionCreators(contactActions.loadContacts, dispatch),
      loadAccountData: bindActionCreators(
        accountDataActions.loadAccountData,
        dispatch
      ),
      loadSmsHistoryRecords: bindActionCreators((from, to) => {
        return smsHistoryActions.loadSmsHistory(from, to);
      }, dispatch),
      getDetailedReport: bindActionCreators((from, to) => {
        return smsHistoryActions.getDetailedReport(from, to);
      }, dispatch),
      sendSms: bindActionCreators(smsActions.sendSms, dispatch),
      sendBulkSms: bindActionCreators(smsActions.sendBulkSms, dispatch),
      sendBulkSmsWithMessage: bindActionCreators(
        smsActions.sendBulkSmsWithMessage,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SMSPage);
