import * as types from "../actions/actionTypes";
import * as accountDataApi from "../../api/accountDataApi";

export function loadAccountDataSuccess(accountData) {
  return { type: types.GET_ACCOUNT_DATA_SUCCESS, accountData };
}

export function loadAccountData() {
  return function (dispatch) {
    return accountDataApi.getAccountData().then((accountData) => {
      dispatch(loadAccountDataSuccess(accountData));
    });
  };
}

export function updateAccountDataSuccess(accountData) {
  return { type: types.UPDATE_ACCOUNT_DATA_SUCCESS, accountData };
}

export function updateAccountData(accountData) {
  return function (dispatch) {
    return accountDataApi.updateAccountData(accountData).then((response) => {
      dispatch(updateAccountDataSuccess(accountData));
    });
  };
}
