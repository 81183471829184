import React from "react";

import queryString from "querystring";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../common/TabPanel";
import BillingDataPage from "./BillingDataPage";
import TariffListPage from "./TariffListPage";
import PaymentsListPage from "./PaymentsListPage";
import TopUpPage from "./TopUpPage";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const PaymentsPage = ({ location, history }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    history.push(`/payments?section=${newValue}`);
  };

  const params = queryString.parse(location.search);
  const section = params.section || params["?section"];
  if (section) {
    if (value !== parseInt(section)) {
      setValue(parseInt(section));
    }
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Billing Data" {...a11yProps(0)} />
          <Tab label="Payments History" {...a11yProps(1)} />
          <Tab label="Tariff" {...a11yProps(2)} />
          <Tab label="Top up" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <BillingDataPage />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PaymentsListPage />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TariffListPage />
      </TabPanel>
      <TabPanel value={value} index={3}>
        {value === 3 ? <TopUpPage /> : <></>}
      </TabPanel>
    </div>
  );
};

export default PaymentsPage;
