import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";

const CancelledPaymentsPage = ({ location, history }) => {
  const goToTopUp = () => {
    history.push("/payments?section=3");
  };
  return (
    <Container style={{ paddingTop: "48px" }}>
      <Grid container maxWidth="md" spacing={10}>
        <Grid item xs={12}>
          <Typography variant="h4" style={{ textAlign: "center" }}>
            Your order has been cancelled!
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            maxWidth="sm"
            variant="contained"
            color="primary"
            onClick={event => goToTopUp()}
          >
            Back to Top Up
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CancelledPaymentsPage;
