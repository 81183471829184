import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as accountDataActions from "../../redux/actions/accountDataActions";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import NotificationContext from "../../NotificationsContext";
import Loading from "../common/Loading";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  navLink: {
    color: "#3f51b5",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

const AccountPage = ({ history, accountData, actions }) => {
  const classes = useStyles();
  const notifications = useContext(NotificationContext);
  !accountData &&
    actions.loadAccountData().catch(error => {
      notifications.showError("Account Data retrieval failed!");
    });
  const [accountDataState, setAccountData] = useState(accountData);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false
  });

  const handleChange = event => {
    setErrors({
      ...errors,
      [event.target.name]: event.target.required && !event.target.value
    });
    setAccountData({
      ...accountDataState,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    setAccountData(accountData);
  }, [accountData]);

  const saveData = event => {
    event.preventDefault();
    let formIsValid = true;
    if (!accountDataState.firstName) {
      errors.firstName = true;
      formIsValid = false;
    }
    if (!accountDataState.lastName) {
      errors.firstName = true;
      formIsValid = false;
    }
    if (!formIsValid) {
      notifications.showError("Please enter all mandatory fields!");
      return;
    }
    actions
      .updateAccountData(accountDataState)
      .then(response => {
        notifications.showSuccess("Account Data updated!");
      })
      .catch(error => {
        notifications.showError("Account Data update failed!");
      });
  };

  return !accountDataState ? (
    <Loading />
  ) : (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Person />
        </Avatar>
        <Typography component="h1" variant="h5">
          Profile Data
        </Typography>
        <form
          className={classes.form}
          onSubmit={event => saveData(event)}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                error={errors.firstName}
                value={accountDataState.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="none"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                error={errors.lastName}
                value={accountDataState.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    accountData: state.accountData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadAccountData: bindActionCreators(
        accountDataActions.loadAccountData,
        dispatch
      ),
      updateAccountData: bindActionCreators(
        accountDataActions.updateAccountData,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
