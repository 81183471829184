import React from "react";
import DataTable from "../common/DataTable";
import * as contactActions from "../../redux/actions/contactActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./ContactsPage.css";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

const ContactsPage = ({ contacts, actions, history }) => {
  let rows = contacts;
  if (!rows) {
    actions.loadContacts().catch(error => {});
    rows = [];
  }
  const selectedItemButtonsArray = [
    {
      component: <DeleteIcon />,
      action: (event, id) => {
        actions.deleteContact(rows.find(row => row.id === id)).then(() => {
          history.push("/contacts");
        });
        return true;
      },
      label: "Delete Contact"
    },
    {
      component: <EditIcon />,
      action: (event, id) => {
        history.push(`/contacts/edit/${id}`);
      },
      label: "Edit Contact"
    }
  ];
  const buttonsArray = [
    {
      component: <AddIcon />,
      action: event => {
        history.push("/contacts/create");
      },
      label: "Create New Contact"
    }
  ];
  const cellDefinitions = [
    {
      id: 1,
      label: "ID",
      numeric: true,
      disablePadding: false,
      dataProp: "id"
    },
    {
      id: 2,
      label: "Name",
      numeric: false,
      disablePadding: false,
      dataProp: "name"
    },
    {
      id: 3,
      label: "Email",
      numeric: true,
      disablePadding: false,
      dataProp: "email"
    },
    {
      id: 4,
      label: "Phone Number",
      numeric: false,
      disablePadding: false,
      dataProp: "msisdn"
    }
  ];
  return (
    <div className="contacts-page">
      <DataTable
        title="Contacts"
        tableCells={cellDefinitions}
        tableData={rows}
        buttonsArray={buttonsArray}
        selectedItemButtonsArray={selectedItemButtonsArray}
      />
    </div>
  );
};

ContactsPage.propTypes = {
  contacts: PropTypes.array,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    contacts: state.contacts
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadContacts: bindActionCreators(contactActions.loadContacts, dispatch),
      deleteContact: bindActionCreators(contactActions.deleteContact, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsPage);
