import React from "react";
import AuthContext from "../AuthContext";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = props => {
  return (
    <AuthContext.Consumer>
      {auth =>
        auth.isLoggedIn() ? (
          <Route {...props}></Route>
        ) : (
          <Redirect to="/login"></Redirect>
        )
      }
    </AuthContext.Consumer>
  );
};

export default PrivateRoute;
