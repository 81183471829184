import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff"
  },
  mainViewBox: {
    maxWidth: "98%",
    height: "98%",
    margin: "1%",
    padding: "3%",
    fontSize: "16pt",
    color: "#111111",
    borderColor: "#efefef",
    borderWidth: "1mm"
  },
  header: {
    textAlign: "right",
    margin: "8mm 0"
  },
  billingInfo: {
    margin: "8mm 0",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center"
  },
  issuer: {
    borderLeftColor: "#efefef",
    borderLeftWidth: "2mm",
    paddingLeft: "4mm",
    height: "100%"
  },
  recipient: {
    borderLeftColor: "#efefef",
    borderLeftWidth: "2mm",
    paddingLeft: "4mm"
  },
  paymentMethod: {
    margin: "0 0 4mm 0",
    color: "#000000"
  },
  paymentMethodHeader: {
    backgroundColor: "#efefef",
    padding: "2mm 4mm",
    fontWeight: "800"
  },
  paymentMethodText: {
    borderColor: "#efefef",
    borderWidth: "1mm",
    padding: "2mm 4mm"
  },
  itemsHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: "#efefef",
    padding: "2mm 4mm",
    alignItems: "center"
  },
  items: {
    borderColor: "#efefef",
    borderWidth: "1mm"
  },
  invoiceItem: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "2mm 4mm",
    alignItems: "center"
  },
  summary: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: "#efefef",
    padding: "2mm 4mm",
    marginTop: "8mm",
    alignItems: "center"
  },
  mgCommLogo: {
    width: "300",
    heigth: "50"
  }
});

export default function PDFInvoice(props) {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.mainViewBox}>
          <Image style={styles.mgCommLogo} src={"/logo.png"} />
          <View style={styles.header}>
            <Text>Invoice #{props.data.invoiceId}</Text>
            <Text>Created: {props.data.dateCreated}</Text>
          </View>
          <View style={styles.billingInfo}>
            <View style={styles.issuer}>
              <Text>MGComm</Text>
              <Text>Angista 6</Text>
              <Text>Sofia, Bulgaria</Text>
            </View>
            <View style={styles.recipient}>
              <Text>{props.data.companyName}</Text>
              <Text>{props.data.vat}</Text>
              <Text>
                {props.data.zipCode}, {props.data.address}
              </Text>
              <Text>
                {props.data.city}, {props.data.country}
              </Text>
            </View>
          </View>
          <View style={styles.paymentMethod}>
            <Text style={styles.paymentMethodHeader}>Payment Method</Text>
            <Text style={styles.paymentMethodText}>PayPal</Text>
          </View>
          <View style={styles.itemsHeader}>
            <Text>Item</Text>
            <Text>Price</Text>
          </View>
          <View style={styles.items}>
            {props.data.items.map(item => {
              return (
                <View key={item.id} style={styles.invoiceItem}>
                  <Text>{item.label}</Text>
                  <Text>{item.price}</Text>
                </View>
              );
            })}
          </View>
          <View style={styles.summary}>
            <Text>Total</Text>
            <Text>{props.data.totalSum}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
