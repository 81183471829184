import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function smsHistoryReducer(
  state = initialState.smsHistory,
  action
) {
  switch (action.type) {
    case types.SMS_HISTORY_SUCCESS:
      return [...action.smsHistory];
    default:
      return state;
  }
}
