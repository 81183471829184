import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Divider } from "@material-ui/core";
import List from "@material-ui/core/List";
import CloseRounded from "@material-ui/icons/CloseRounded";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Textsms from "@material-ui/icons/Textsms";
import Contacts from "@material-ui/icons/Contacts";
import Payment from "@material-ui/icons/Payment";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import { withRouter } from "react-router-dom";
import logo from "../../assets/logo_no_text.png";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    display: "flex",
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    filter: "brightness(0) invert(1)",
    height: "45px",
    position: "absolute",
    left: "calc(50% - 43px)",
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  },
}));

const baseUrl = "";

const Header = ({ onMenuToggled, onLogout, menuOpened, history }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = useState(menuOpened);
  const titleMap = {
    "/contacts": "Contacts",
    "/": "Dashboard",
    "/sms": "SMS Gateway",
    "/payments": "Payments",
    "/payments/billing-data": "Payments",
    "/payments/created": "Payment status",
    "/payments/cancelled": "Payment status",
    "/account": "Profile",
  };
  const title = titleMap[window.location.pathname.substr(baseUrl.length)];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    onLogout();
  };

  const toggleDrawerState = () => {
    onMenuToggled(!drawerOpen);
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuItemClick = (menuItem) => {
    history.push(menuItem.path);
  };

  const goToProfile = () => {
    history.push("/account");
    setAnchorEl(null);
  };
  const goToPaymentData = () => {
    history.push("/payments?section=0");
    setAnchorEl(null);
  };

  const menuItems = [
    {
      id: 1,
      text: "Dashboard",
      menuIcon: <DashboardIcon />,
      path: "/",
    },
    {
      id: 2,
      text: "SMS Gateway",
      menuIcon: <Textsms />,
      path: "/sms",
    },
    {
      id: 3,
      text: "Contacts",
      menuIcon: <Contacts />,
      path: "/contacts",
    },
    {
      id: 4,
      text: "Payments",
      menuIcon: <Payment />,
      path: "/payments",
    },
  ];

  return (
    <>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawerState}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            {drawerOpen ? <CloseRounded /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <img src={logo} className={classes.logo} alt="logo" />
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={goToProfile}>Profile</MenuItem>
              <MenuItem onClick={goToPaymentData}>Billing Data</MenuItem>
              <Divider />
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbar}></div>
        <Divider />
        <List>
          {menuItems.map((menuItem, index) => (
            <ListItem
              button
              key={menuItem.id}
              onClick={() => handleMenuItemClick(menuItem)}
            >
              <ListItemIcon>{menuItem.menuIcon}</ListItemIcon>
              <ListItemText primary={menuItem.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default withRouter(Header);
