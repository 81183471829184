import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as accountDataActions from "../../redux/actions/accountDataActions";
import * as paymentActions from "../../redux/actions/paymentsActions";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../common/Loading";

const useStyles = makeStyles(theme => ({
  card: {
    textAlign: "center",
    padding: "16px",
    backgroundColor: "#f5f5f5"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  balance: {
    fontSize: "22px"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const TopUpPage = ({ accountData, actions }) => {
  const classes = useStyles();
  !accountData && actions.loadAccountData().catch(error => {});
  const [accountDataState, setAccountData] = useState(accountData);
  const [amount, setAmount] = useState({
    value: 0
  });

  useEffect(() => {
    setAccountData(accountData);
    accountData &&
      accountData.payPalUrl !== "" &&
      (window.location = accountData.payPalUrl);
  }, [accountData]);

  const handleAmountChange = event => {
    setAmount({
      ...amount,
      value: event.target.value
    });
  };
  const submitPaymentRequest = event => {
    event.preventDefault();
    actions.createPayment(amount.value, "EUR");
  };
  return !accountDataState ? (
    <Loading />
  ) : (
    <>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Container className={classes.card} maxWidth="xl">
              <Typography variant="h5">
                Your current balance is:
                <Typography
                  component="span"
                  color="primary"
                  className={classes.balance}
                >
                  {accountDataState.balance} EUR
                </Typography>
              </Typography>
            </Container>
          </Grid>
          <Grid item md={3} />
          <Grid item md={6} xs={12}>
            <form
              className={classes.form}
              onSubmit={event => submitPaymentRequest(event)}
              noValidate
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="amount"
                    label="Amount (net) in EUR"
                    name="amount"
                    value={amount.value}
                    onChange={handleAmountChange}
                  />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    spacing={4}
                    className={classes.submit}
                    disabled={!amount.value}
                  >
                    Purchase credit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return {
    accountData: state.accountData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadAccountData: bindActionCreators(
        accountDataActions.loadAccountData,
        dispatch
      ),
      createPayment: bindActionCreators(paymentActions.createPayment, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopUpPage);
