import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DataTable from "../common/DataTable";
import * as paymentsActions from "../../redux/actions/paymentsActions";
import * as billingDataActions from "../../redux/actions/billingDataActions";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PDFInvoice from "../common/PDFInvoice";
import { BlobProvider } from "@react-pdf/renderer";

const PaymentsListPage = ({ payments, billingData, actions }) => {
  if (!payments) actions.loadPayments().catch(error => {});
  if (!billingData) actions.loadBillingData().catch(error => {});
  const rows = (payments || []).map(({ id, amount, dateCreated }) => {
    return {
      id,
      amount: amount + " EUR",
      date: `${dateCreated}`
    };
  });
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});

  const cellDefinitions = [
    {
      id: 1,
      label: "ID",
      numeric: true,
      disablePadding: false,
      dataProp: "id"
    },
    {
      id: 2,
      label: "Amount",
      numeric: false,
      disablePadding: false,
      dataProp: "amount"
    },
    {
      id: 3,
      label: "Date",
      numeric: true,
      disablePadding: false,
      dataProp: "date"
    }
  ];
  const selectedItemButtons = [
    {
      component: <LocalAtmIcon />,
      action: (event, id) => {
        const payment = payments.find(p => parseInt(p.id) === id);
        console.log(invoiceData);
        setInvoiceData({
          companyName: billingData.companyName,
          vat: billingData.vat,
          country: billingData.country,
          city: billingData.city,
          address: billingData.address,
          zipCode: billingData.zipCode,
          invoiceId: payment.id,
          dateCreated: payment.dateCreated,
          items: [
            {
              id: 1,
              label: "Top up",
              price: `${parseFloat(payment.amount).toFixed(2)} EUR`
            },
            {
              id: 2,
              label: "VAT",
              price: `${(parseFloat(payment.amount) * 0.2).toFixed(2)} EUR`
            }
          ],
          totalSum: `${(parseFloat(payment.amount) * 1.2).toFixed(2)} EUR`
        });
        setInvoiceLoading(true);
      },
      label: "Issue invoice"
    }
  ];
  return (
    <div className="contacts-page">
      {invoiceLoading ? (
        <BlobProvider document={<PDFInvoice data={invoiceData} />}>
          {({ blob, url, loading, error }) => {
            if (loading) return <></>;
            var a = document.createElement("a");
            var dlPdfUrl = window.URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            a.setAttribute("href", dlPdfUrl);
            a.setAttribute("download", "Invoice");
            document.getElementsByTagName("body")[0].append(a);
            setTimeout(() => {
              a.click();
              window.URL.revokeObjectURL(dlPdfUrl);
              a.remove();
            }, 100);
            setInvoiceLoading(false);
            return <></>;
          }}
        </BlobProvider>
      ) : (
        <> </>
      )}
      <DataTable
        title="Payments"
        tableCells={cellDefinitions}
        tableData={rows}
        selectedItemButtonsArray={selectedItemButtons}
      />
    </div>
  );
};

PaymentsListPage.propTypes = {
  payments: PropTypes.array,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    payments: state.payments,
    billingData: state.billingData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadPayments: bindActionCreators(paymentsActions.loadPayments, dispatch),
      loadBillingData: bindActionCreators(
        billingDataActions.loadBillingData,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsListPage);
