import { API_BASE_URI, jsonBasedFetch } from "./apiUtils";
import {
  USER_NAME_SESSION_ATTRIBUTE_NAME,
  DOMAIN_SESSION_ATTRIBUTE_NAME
} from "../auth/Auth";

export function updateContact(contact) {
  return jsonBasedFetch(`${API_BASE_URI}/contact/`, {
    method: "PUT",
    body: JSON.stringify(contact)
  });
}

export function deleteContact(contact) {
  return jsonBasedFetch(`${API_BASE_URI}/contact/`, {
    method: "DELETE",
    body: JSON.stringify(contact)
  });
}

export function createContact(contact) {
  const username = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
  const domain = sessionStorage.getItem(DOMAIN_SESSION_ATTRIBUTE_NAME);
  contact.user = username;
  contact.customer = domain;
  return jsonBasedFetch(`${API_BASE_URI}/contact/`, {
    method: "POST",
    body: JSON.stringify(contact)
  }).then(responseObject => responseObject.crudl.contact[0]);
}

export function getContacts() {
  return jsonBasedFetch(`${API_BASE_URI}/contact/all`, {
    method: "GET"
  }).then(responseObject => responseObject.getUserContactsAndGroups.contact);
}
