import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as accountDataActions from "../../redux/actions/accountDataActions";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography
} from "@material-ui/core";
import Loading from "../common/Loading";

const DashboardPage = ({ history, accountData, actions }) => {
  const [errorText, setErrorText] = useState("");

  !accountData &&
    actions.loadAccountData().catch(error => {
      setErrorText("Could not retrieve Account Data - please try again!");
    });

  const goToSMSGateway = () => {
    history.push("/sms");
  };

  const goToContacts = () => {
    history.push("/contacts");
  };

  const goToTopUp = () => {
    history.push("/payments?section=3");
  };

  const goToBillingData = () => {
    history.push("/payments?section=0");
  };

  return !accountData ? (
    <Loading />
  ) : (
    <Container maxWidth="lg" style={{ paddingTop: "24px" }}>
      <Grid container spacing={4}>
        {accountData ? (
          <Grid item xs={12}>
            <Typography variant="h4" color="textPrimary">
              Hi, {accountData.firstName} {accountData.lastName}!
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Send SMS
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Use your SMS Gateway to send a single or bulk sms
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={event => goToSMSGateway()}
              >
                Go To SMS Gateway
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Manage Contacts
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Preview, create, edit and delete your contacts so you can
                convevniently send SMS
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={event => goToContacts()}
              >
                Go To Contacts
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Billing Data
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                YSetup your billing data - it will be needed when issuing
                invoices
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={event => goToBillingData()}
              >
                Go To Billing Data
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {accountData
                  ? "Your prepaid account balance is " +
                    accountData.balance +
                    " EUR"
                  : "Loading..."}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={event => goToTopUp()}
              >
                Top Up
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    accountData: state.accountData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadAccountData: bindActionCreators(
        accountDataActions.loadAccountData,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
