import React, { useState } from "react";
import queryString from "querystring";
import * as paymentsApi from "../../api/paymentsApi";
import Loading from "../common/Loading";
import { Typography, Container, Grid, Button } from "@material-ui/core";

const CreatedPaymentsPage = ({ location, history }) => {
  const params = queryString.parse(location.search);
  const [confirmingPayment, setConfirmingPayment] = useState(true);
  const [error, setError] = useState("");

  paymentsApi
    .confirmPayment(params.paymentId || params["?paymentId"])
    .then(() => {
      setConfirmingPayment(false);
    })
    .catch(error => {
      setConfirmingPayment(false);
      setError("Something went wrong during top up - please contact support!");
    });

  const goToDashboard = () => {
    history.push("/");
  };

  return (
    <Container style={{ paddingTop: "48px" }} maxWidth="lg">
      <Grid container spacing={10} alignContent="center" alignItems="center">
        {confirmingPayment ? (
          <Loading />
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h4" style={{ textAlign: "center" }}>
                {error ? error : "Your purchase has been successful!"}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                maxWidth="sm"
                variant="contained"
                color="primary"
                onClick={event => goToDashboard()}
              >
                Go To Dashboard
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default CreatedPaymentsPage;
