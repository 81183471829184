// import { handleResponse, handleError } from "./apiUtils";
import { API_BASE_URI, jsonBasedFetch } from "./apiUtils";

export function getPayments() {
  return jsonBasedFetch(`${API_BASE_URI}/payments/`);
}

export function createPayment(amount, currency, returnURL, cancelURL) {
  return jsonBasedFetch(`${API_BASE_URI}/payments/`, {
    method: "POST",
    body: JSON.stringify({
      amount: amount.toString(),
      currency,
      returnURL,
      cancelURL
    })
  });
}

export function confirmPayment(id) {
  return jsonBasedFetch(`${API_BASE_URI}/payments/${id}`, {
    method: "PUT"
  });
}
