import { API_BASE_URI, jsonBasedFetch, csvFetch } from "./apiUtils";

let makeTwoDigit = (digit) => {
  if (digit < 10) return "0" + digit;
  else return digit;
};
let convertDate = (date) => {
  return (
    makeTwoDigit(date.getMonth() + 1) +
    "/" +
    makeTwoDigit(date.getDate()) +
    "/" +
    date.getFullYear()
  );
};

export function getSmsHistory(fromDate, toDate) {
  return jsonBasedFetch(
    `${API_BASE_URI}/sms/history?from=${fromDate && convertDate(fromDate)}&to=${
      toDate && convertDate(toDate)
    }`,
    {
      method: "GET",
    }
  ).then((responseObject) =>
    responseObject.data.map((historyRecord) => {
      let sendDateUTCDate = new Date(historyRecord.sendDateUTC * 1000);
      return {
        ...historyRecord,
        sendDateUTC: sendDateUTCDate
          .toString()
          .substring(0, sendDateUTCDate.toString().indexOf(" (")),
        status: responseObject.statusMap[historyRecord.status] || "Unkown",
      };
    })
  );
}

export function getSmsHistoryDetailedReport(fromDate, toDate) {
  return csvFetch(
    `${API_BASE_URI}/sms/historyDetailedReport?from=${
      fromDate && convertDate(fromDate)
    }&to=${toDate && convertDate(toDate)}`,
    {
      method: "GET",
    }
  ).then((responseObject) => {
    console.log(responseObject);
  });
}
