import * as types from "../actions/actionTypes";
import * as smsHistoryApi from "../../api/smsHistoryApi";

export function loadSmsHistorySuccess(smsHistory) {
  return { type: types.SMS_HISTORY_SUCCESS, smsHistory };
}

export function loadSmsHistory(fromDate, toDate) {
  return function (dispatch) {
    return smsHistoryApi.getSmsHistory(fromDate, toDate).then((smsHistory) => {
      dispatch(loadSmsHistorySuccess(smsHistory));
    });
  };
}

export function getDetailedReport(fromDate, toDate) {
  return function (dispatch) {
    return smsHistoryApi
      .getSmsHistoryDetailedReport(fromDate, toDate)
      .then((smsHistory) => {});
  };
}
