import { API_BASE_URI, jsonBasedFetch } from "../api/apiUtils";

export const USER_NAME_SESSION_ATTRIBUTE_NAME =
  "USER_NAME_SESSION_ATTRIBUTE_NAME";
export const DOMAIN_SESSION_ATTRIBUTE_NAME = "DOMAIN_SESSION_ATTRIBUTE_NAME";

export class Auth {
  _userData = {};
  loggedStateHandlers = [];
  constructor(_loggedIn) {
    const username = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    if (username === null) this.loggedIn = false;
    else {
      this.loggedIn = true;
      // this.getUserData().then(userData => this.setStoredUserData(userData));
    }
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  addLoggedStateChangeHandler(handler) {
    this.loggedStateHandlers.push(handler);
  }

  removeLoggedStateChangeHandler(handler) {
    this.loggedStateHandlers = this.loggedStateHandlers.filter(
      h => h !== handler
    );
  }

  notifyLoggedStateHandlers() {
    this.loggedStateHandlers.forEach(handler => handler());
  }

  registerSuccessfulLogin(username, domain) {
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
    sessionStorage.setItem(DOMAIN_SESSION_ATTRIBUTE_NAME, domain);
    this.loggedIn = true;
    this.notifyLoggedStateHandlers();
  }

  logout() {
    sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    sessionStorage.removeItem(DOMAIN_SESSION_ATTRIBUTE_NAME);
    this.loggedIn = false;
    this.notifyLoggedStateHandlers();
  }

  increaseBalance() {
    this._userData = {
      ...this._userData,
      balance: this._userData.balance + 50
    };
  }

  getStoredUserData() {
    return this._userData;
  }

  setStoredUserData(userData) {
    this._userData = userData;
  }

  getUserData() {
    const username = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    const domain = sessionStorage.getItem(DOMAIN_SESSION_ATTRIBUTE_NAME);

    return fetch(`${API_BASE_URI}/users/${domain}/${username}`).then(response =>
      response.json()
    );
  }

  attemptRegister({ username, password, domain, firstName, lastName, email }) {
    return jsonBasedFetch(`${API_BASE_URI}/users/`, {
      method: "POST",
      body: JSON.stringify({
        username,
        domain,
        password,
        firstName,
        lastName,
        email
      })
    });
  }

  attemptLogin(username, password, domain) {
    const formData = [];

    formData.push("username=" + username);
    formData.push("password=" + password);
    formData.push("domain=" + domain);

    const loginUrl = `${API_BASE_URI}/login`;
    return fetch(loginUrl, {
      method: "POST",
      body: formData.join("&"),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json"
      }
    });
  }
}

const auth = new Auth();

export default auth;
